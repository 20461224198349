import {Analytics, getAnalytics, isSupported} from 'firebase/analytics';
import {AppConfig, CoversConfig, DashboardConfig, PrivacyPolicyConfig, WalletConfig} from '@shared/models/configs';
import {CollectionReference, DocumentData, DocumentReference, collection, collectionGroup, doc, getFirestore} from 'firebase/firestore';
import {CompetitionGroupFixtureList, Fixture} from '@shared/models/fixture';
import {Entity, EntityPublicData} from '@shared/models/entity';
import {Notification, NotificationConfig} from '@shared/models/notification';
import {ReCaptchaV3Provider, initializeAppCheck} from 'firebase/app-check';
import {Transaction, Wallet} from '@shared/models/wallet';
import {ACL} from '@shared/models/acl';
import {Category} from '@shared/models/category';
import {CheckoutSession} from '@shared/models/checkout';
import {Competition} from '@shared/models/competition';
import {CompetitionGroup} from '@shared/models/competition-group';
import {CompetitionGroupLeagueTable} from '@shared/models/league-table';
import {CompetitionGroupParticipants} from '@shared/models/participant';
import {CompetitionStage} from '@shared/models/competition-stage';
import {FixtureContactInfo} from '@shared/models/contact-info';
import {FixtureConverter} from '@ui/helpers/firebase-converters';
import {Invitation} from '@shared/models/invitation';
import {NewsItem} from '@shared/models/newsItem';
import {Order} from '@shared/models/order';
import {Post} from '@shared/models/post';
import {Promo} from '@shared/models/promo';
import {Registration} from '@shared/models/registration';
import {ShopItem} from '@shared/models/shopItem';
import {Streak} from '@shared/models/wallet/streak';
import {Team} from '@shared/models/team';
import {Template} from '@shared/models/template';
import {User} from '@shared/models/user';
import {UserFeedback} from '@shared/models/userFeedback';
import {Verification} from '@shared/models/verification';
import {getFunctions} from 'firebase/functions';
import {initializeApp} from 'firebase/app';

// const STAGE = process.env.NEXT_PUBLIC_STAGE || 'local';
// const NODE_ENV = process.env.NODE_ENV;

let initialized = false;
let analytics: Analytics | undefined = undefined;

const initFirebase = () => {
    if (initialized) {
        return;
    }

    const config = {
        apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
        authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
        projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
        storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
        measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    };

    const app = initializeApp(config);
    const functions = getFunctions(app);
    functions.region = 'europe-west1';
    // const firestore = initializeFirestore(app, {
    //     experimentalForceLongPolling: true,
    // });
    // const db = getFirestore(app);

    // Check if running in DEV
    // if (NODE_ENV === 'development') {
    //     console.log('Switching to local Firebase instance...');
    //     const origin = 'localhost';

    //     connectFunctionsEmulator(functions, origin, 5001);

    //     if (!get(db, '_settingsFrozen', false)) {
    //         connectFirestoreEmulator(firestore, origin, 8080);
    //     }
    //     connectAuthEmulator(getAuth(), `http://${origin}:9099`);
    //     connectStorageEmulator(getStorage(), origin, 9199);

    // }
    isSupported().then(yes => yes ? analytics = getAnalytics(app) : null);
    initialized = true;
};

const initAppCheck = () => {
    // if (NODE_ENV === 'development') {
    //     console.log('Initializing App Check...', NODE_ENV, STAGE, process.env.NEXT_PUBLIC_RECAPTCHA);
    //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    // }


    initializeAppCheck(getFunctions().app, {
        provider: new ReCaptchaV3Provider(process.env.NEXT_PUBLIC_RECAPTCHA!),

        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true,
    });
};

const createCollection = <T = DocumentData>(collectionName: string) => {
    return collection(getFirestore(), collectionName) as CollectionReference<T>;
};

const createCollectionGroup = <T = DocumentData>(collectionName: string) => {
    return collectionGroup(getFirestore(), collectionName) as CollectionReference<T>;
};

const createDocument = <T = DocumentData>(collectionName: string, documentId: string) => {
    return doc(getFirestore(), collectionName, documentId) as DocumentReference<T>;
};

type Collections = {
    categories: CollectionReference<Category>;
    competitions: CollectionReference<Competition>;
    competitionGroups: CollectionReference<CompetitionGroup>;
    competitionStages: CollectionReference<CompetitionStage>;
    competitionGroupFixtureLists: (competitionGroupId: string) => CollectionReference<CompetitionGroupFixtureList>;
    competitionGroupLeagueTables: (competitionGroupId: string) => CollectionReference<CompetitionGroupLeagueTable>;
    competitionGroupParticipants: (competitionGroupId: string) => CollectionReference<CompetitionGroupParticipants>;
    entities: CollectionReference<Entity>;
    fixtures: CollectionReference<Fixture>;
    invitations: CollectionReference<Invitation>;
    newsItems: CollectionReference<NewsItem>;
    notifications: CollectionReference<Notification>;
    orders: (uid: string) => CollectionReference<Order>;
    posts: CollectionReference<Post>;
    promos: CollectionReference<Promo>;
    registrations: CollectionReference<Registration>;
    shopItems: CollectionReference<ShopItem>;
    streaks: (uid: string) => CollectionReference<Streak>;
    templates: CollectionReference<Template>;
    transactions: (walletId: string) => CollectionReference<Transaction>;
    users: CollectionReference<User>,
    userFeedback: CollectionReference<UserFeedback>,
};

type Docs = {
    acl: (uid: string) => DocumentReference<ACL>;
    appConfig: () => DocumentReference<AppConfig>;
    category: (id: string) => DocumentReference<Category>;
    checkout: (uid: string, id: string) => DocumentReference<CheckoutSession>;
    checkouts: (uid: string) => CollectionReference<CheckoutSession>;
    competition: (id: string) => DocumentReference<Competition>;
    competitionGroup: (id: string) => DocumentReference<CompetitionGroup>;
    competitionStage: (stageId: string) => DocumentReference<CompetitionStage>;
    contactInfo: (id: string) => DocumentReference<FixtureContactInfo>;
    coversConfig: () => DocumentReference<CoversConfig>;
    dashboardConfig: () => DocumentReference<DashboardConfig>;
    entity: (id: string) => DocumentReference<Entity>;
    fixture: (id: string) => DocumentReference<Fixture>;
    invitation: (id: string) => DocumentReference<Invitation>;
    newsItem: (id: string) => DocumentReference<NewsItem>;
    notificationConfig: (uid: string, id: string) => DocumentReference<NotificationConfig>;
    order: (uid: string, id: string) => DocumentReference<Order>;
    post: (id: string) => DocumentReference<Post>;
    privacyPolicy: () => DocumentReference<PrivacyPolicyConfig>;
    promo: (id: string) => DocumentReference<Promo>;
    publicUser: (userId: string) => DocumentReference<User>;
    publicEntity: (id: string) => DocumentReference<EntityPublicData>;
    shopItem: (id: string) => DocumentReference<ShopItem>;
    team: (id: string) => DocumentReference<Team>;
    template: (id: string) => DocumentReference<Template>;
    user: (userId: string) => DocumentReference<User>;
    verification: (id: string) => DocumentReference<Verification>;
    wallet: (uid: string) => DocumentReference<Wallet>;
    walletConfig: () => DocumentReference<WalletConfig>;
};

type CollectionGroups = {
    competitionGroupParticipants: CollectionReference<CompetitionGroupParticipants>;
    allOrders: CollectionReference<Order>;
}

let collections: Collections;
let collectionGroups: CollectionGroups;
let docs: Docs;

const getCollections = (): Collections => {
    if (!collections) {
        collections = {
            categories: createCollection<Category>('categories'),
            competitions: createCollection<Competition>('competitions'),
            competitionGroupFixtureLists: (competitionGroupId: string) =>
                createCollection<CompetitionGroupFixtureList>(`competitionGroups/${competitionGroupId}/fixtures`),
            competitionGroupLeagueTables: (competitionGroupId: string) =>
                createCollection<CompetitionGroupLeagueTable>(`competitionGroups/${competitionGroupId}/leagueTables`),
            competitionGroupParticipants: (competitionGroupId: string) =>
                createCollection<CompetitionGroupParticipants>(`competitionGroups/${competitionGroupId}/participants`),
            competitionGroups: createCollection<CompetitionGroup>('competitionGroups'),
            competitionStages: createCollection<CompetitionStage>('competitionStages'),
            entities: createCollection<Entity>('entities'),
            fixtures: createCollection<Fixture>('fixtures').withConverter<Fixture>(FixtureConverter),
            invitations: createCollection<Invitation>('invitations'),
            newsItems: createCollection<NewsItem>('newsItems'),
            notifications: createCollection<Notification>('notifications'),
            orders: (uid: string) => createCollection<Order>(`wallets/${uid}/orders`),
            posts: createCollection<Post>('posts'),
            promos: createCollection<Promo>('promos'),
            registrations: createCollection<Registration>('registrations'),
            shopItems: createCollection<ShopItem>('shopItems'),
            streaks: (uid: string) => createCollection<Streak>(`wallets/${uid}/streaks`),
            templates: createCollection<Template>('templates'),
            transactions: (walletId: string) => createCollection<Transaction>(`wallets/${walletId}/transactions`),
            userFeedback: createCollection<UserFeedback>('userFeedback'),
            users: createCollection<User>('users'),
        };
    }
    return collections;
};

const getDocs = (): Docs => {
    if (!docs) {
        docs = {
            acl: (uid: string) => createDocument<ACL>('acl', uid),
            appConfig: () => createDocument<AppConfig>('configs', 'app'),
            category: (id: string) => createDocument<Category>('categories', id),
            checkout: (uid, id) => createDocument(`customersV2/${uid}/checkout_sessions`, `${id}`),
            checkouts: (uid: string) => createCollection<CheckoutSession>(`customersV2/${uid}/checkout_sessions`),
            competition: (id: string) => createDocument<Competition>('competitions', id),
            competitionGroup: (id: string) => createDocument<CompetitionGroup>('competitionGroups', id),
            competitionStage: (stageId: string) => createDocument<CompetitionStage>('competitionStages', stageId),
            contactInfo: (id: string) => createDocument<FixtureContactInfo>('contactInfo', id),
            coversConfig: () => createDocument<CoversConfig>('configs', 'covers'),
            dashboardConfig: () => createDocument<DashboardConfig>('configs', 'dashboard'),
            entity: (id: string) => createDocument<Entity>('entities', id),
            fixture: (id: string) => createDocument<Fixture>('fixtures', id).withConverter(FixtureConverter),
            invitation: (id: string) => createDocument<Invitation>('invitations', id),
            newsItem: (id: string) => createDocument<NewsItem>('newsItems', id),
            notificationConfig: (uid: string, id: string) => createDocument<NotificationConfig>('notificationConfigs', `${uid}:${id}`),
            order: (uid:string, id: string) => createDocument<Order>(`wallets/${uid}/orders`, id),
            post: (id: string) => createDocument<Post>('posts', id),
            privacyPolicy: () => createDocument<PrivacyPolicyConfig>('configs', 'privacy'),
            promo: (id: string) => createDocument<Promo>('promos', id),
            publicUser: (userId: string) => createDocument<User>(`users/${userId}/public`, 'profile'),
            publicEntity: (entityId: string) => createDocument<EntityPublicData>(`entities/${entityId}/public`, 'profile'),
            shopItem: (id: string) => createDocument<ShopItem>('shopItems', id),
            team: (id: string) => createDocument<Team>('teams', id),
            template: (id: string) => createDocument<Template>('templates', id),
            user: (userId: string) => createDocument<User>('users', userId),
            verification: (id: string) => createDocument<Verification>('verifications', id),
            wallet: (uid: string) => createDocument<Wallet>('wallets', uid),
            walletConfig: () => createDocument<WalletConfig>('configs', 'wallet'),
        };
    }
    return docs;
};

const getCollectionGroups = (): CollectionGroups => {
    if (!collectionGroups) {
        collectionGroups = {
            competitionGroupParticipants: createCollectionGroup<CompetitionGroupParticipants>('participants'),
            allOrders: createCollectionGroup<Order>('orders'),
        };

    }
    return collectionGroups;
};

type CollectionsKey = keyof Collections;
type CollectionGroupsKey = keyof CollectionGroups;
type DocsKey = keyof Docs;

export type {CollectionsKey, CollectionGroupsKey, DocsKey};

export {getCollections, getDocs, getCollectionGroups, initFirebase, initAppCheck, analytics};
