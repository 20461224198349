import {Event, WalletEventType} from './wallet/event';
import {Participant} from './participant';

interface Wallet {
    id: string;
    uid: string;
    balance: number;
    currency: 'Divots';
}

enum TransactionActions {
    ACCOUNT_CREATION = 'account_creation',
    ACCOUNT_COMPLETION = 'account_completion',
    CHALLENGE_JOINED = 'challenge_joined',
    CHALLENGE_FINISHED = 'challenge_finished',
    CHALLENGE_FINISHED_MULTIPLE = 'challenge_finished_multiple',

    COMMUNITY_JOINED = 'community_joined',

    FIXTURE_CREATION = 'fixture_creation',
    FIXTURE_BET = 'fixture_bet',
    FIXTURE_MICRO_BET = 'fixture_micro_bet',
    FIXTURE_PAYOUT = 'fixture_payout',
    FIXTURE_MICRO_BET_PAYOUT = 'fixture_micro_bet_payout',

    LOGIN_DAILY = 'login_daily',
    LOGIN_FULL_WEEK = 'login_full_week',
    LOGIN_MONTHLY = 'login_monthly',

    LAST_LOGIN_MONTH_AGO = 'last_login_month_ago',

    ORDER_ITEM_BOUGHT = 'order_item_bought',
    TEAM_CREATION = 'team_creation',
    USER_INVITE = 'user_invite',
    USER_INVITE_MULTIPLE = 'user_invite_multiple',
    VIDEO_VIEWED = 'video_viewed',

    WIN_BET = 'win_bet',
    LOSE_BET = 'lose_bet',
    PLAY_BET = 'play_bet',
    PLAY_DRILL = 'play_drill',

    PROMO_REDEMPTION = 'promo_redemption',
}

const CreditActions = [
    TransactionActions.ACCOUNT_CREATION,
    TransactionActions.ACCOUNT_COMPLETION,
    TransactionActions.TEAM_CREATION,
    TransactionActions.CHALLENGE_JOINED,
    TransactionActions.CHALLENGE_FINISHED,
    TransactionActions.CHALLENGE_FINISHED_MULTIPLE,
    TransactionActions.COMMUNITY_JOINED,
    TransactionActions.USER_INVITE,
    TransactionActions.USER_INVITE_MULTIPLE,
    TransactionActions.LOGIN_DAILY,
    TransactionActions.LOGIN_FULL_WEEK,
    TransactionActions.FIXTURE_CREATION,
    TransactionActions.FIXTURE_PAYOUT,
    TransactionActions.VIDEO_VIEWED,
    TransactionActions.WIN_BET,
    TransactionActions.LOSE_BET,
    TransactionActions.PLAY_BET,
    TransactionActions.PLAY_DRILL,
    TransactionActions.PROMO_REDEMPTION,
];

const DebitActions = [
    TransactionActions.FIXTURE_BET,
    TransactionActions.FIXTURE_MICRO_BET,
    TransactionActions.ORDER_ITEM_BOUGHT,
];

type TransactionType = 'debit' | 'credit';

enum TransactionStatus {
    PENDING = 'pending',
    COMPLETED = 'completed',
    FAILED = 'failed',
}

interface Transaction {
    id: string;
    uid: string;
    walletId: string;
    timestamp: Date;
    description: string;
    type: TransactionType;
    amount: number;
    // The participant that initiated this transaction
    sender?: Participant;
    // The participant that received this transaction
    receiver?: Participant;

    documentId?: string; // if this transaction is related to a document
    betId?: string; // if this transaction is related to a bet
    orderId?: string; // if this transaction is related to an order
    action?: TransactionActions; // if this transaction is related to an action
    status?: TransactionStatus;
    challengeId?: string; // if this transaction is related to a challenge
}

const walletId = (uid: string) => uid;

interface WalletSendDivotsRequest {
    transactionId: string;
    receiverUid: string;
    amount: number;
    isTopUp?: boolean;
    topUpMessage?: string;
}

interface WalletSendDivotsResponse {
    transactionId: string;
}

export type {
    Wallet,
    Transaction,
    TransactionType,
    WalletSendDivotsRequest,
    WalletSendDivotsResponse,
    Event,
};

export {
    walletId,
    TransactionActions,
    CreditActions,
    DebitActions,
    TransactionStatus,
    WalletEventType,
};
